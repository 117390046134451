import React from 'react';
import axios from 'axios';

const Element = props => {
  return props.children;
};

class Form extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: ''
    }
  }

  handleSubmit(e){
    e.preventDefault();
    axios({
      method: "POST",
      url:"https://prod-28.centralus.logic.azure.com:443/workflows/ce29f378395245b08090935fa449a6c9/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=bzJehT9XgM_dLsB_yJTaocQo_kCATKbwtOH1Y-SgSlQ",
      data:  this.state
    }).then((response)=>{
      console.log(response);
      if (response.status === 202) {
        window.location.assign('/sent');
        this.resetForm()
      } else if (response.status !== 202) {
        alert("Message failed to send.")
      }
    })
  }

  resetForm(){
    this.setState({email: ''})
  }

  render() {
    return(
<Element>

        <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
       
        <h3>Join our email list</h3>
        <p>We routinely send our current availability and pricing</p>
								<input type="email" name="register-email" id="email" defaultValue="" placeholder="Email" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
                <br />
                <input type="submit" value="Sign up" className="primary" />

        </form>
     
      </Element> 
    );
  }

  onNameChange(event) {
	  this.setState({name: event.target.value})
  }

  onEmailChange(event) {
	  this.setState({email: event.target.value})
  }

  onMessageChange(event) {
	  this.setState({message: event.target.value})
  }
}

export default Form;
