import React from 'react';
import Form from '../components/Form';
import acorus from '../assets/images/acorus.jpg';
import asiaticjasmine from '../assets/images/asiaticjasmine.jpg';
import variegata from '../assets/images/variegata.jpg';
import chocolatechip from '../assets/images/chocolatechip.jpg';
import autumn from '../assets/images/autumn.jpg';
import dwarfmondo from '../assets/images/dwarfmondo.jpg';
import june from '../assets/images/june.jpg';
import silverdragon from '../assets/images/silverdragon.jpg';
import Scroll from '../components/Scroll';

import Layout from '../components/Layout';

const IndexPage = () => (
	<Layout fullMenu>
		<article id="main">


			<header>
				<h2>What we're growing</h2>
				<Scroll type="id" element="one">
					<a href="#one" className="button">
						Perennials and Groundcover
					</a>
				</Scroll>
				<Scroll type="id" element="two">
					<a href="#two" className="button">
						Hosta
					</a>
				</Scroll>
				<Scroll type="id" element="three">
					<a href="#three" className="button">
						Ferns
					</a>
				</Scroll>
				<Scroll type="id" element="four">
					<a href="#four" className="button">
						Grasses
					</a>
				</Scroll>
			</header>

			<section className="wrapper style5 special">

				<hr id='one' />
				<div className="inner">

					<h3  >Perennials and Groundcover</h3>

					<Scroll type="id" element="two">
						<a href="#two" className="button">
							Hosta
						</a>
					</Scroll>
					<Scroll type="id" element="three">
						<a href="#three" className="button">
							Ferns
						</a>
					</Scroll>
					<Scroll type="id" element="four">
						<a href="#four" className="button">
							Grasses
						</a>
					</Scroll>
					<div className="table-wrapper">
						<ul className="features">

							<li className="">
								<div className="image fit">
									<img src={chocolatechip} alt="" />
								</div>
								<h3>Chocolate Chip Ajuga</h3>
								<p>Ajuga reptans 'Chocolate Chip'</p>
							</li>
							<li className="">
								<div className="image fit">
									<img src={asiaticjasmine} alt="" />
								</div>
								<h3>Asiatic Jasmine</h3>
								<p>Trachelospermum asiaticum</p>
							</li>
						</ul>
						<table className="alt">
							<thead>
								<tr>
									<th>Botanical Name</th>
									<th>Common Name</th>
									<th>Size</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Achillea' Desert Eve™ Yellow'</td>
									<td> 'Desert Eve™ Deep Yellow' Yarrow</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Achillea' Desert Eve™ Deep Red'</td>
									<td> 'Desert Eve™ Deep Red' Yarrow</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Achillea millefolium 'Terra Cotta'</td>
									<td> 'Terra Cotta' Yarrow</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Agapanthus africanus</td>
									<td>Lily of the Nile Agapanthus</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Ajuga reptans 'Bronze Beauty'</td>
									<td> 'Bronze Beauty' Ajuga</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Ajuga reptans 'Black Scallop'</td>
									<td> 'Black Scallop' Ajuga</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Ajuga reptans 'Chocolate Chip'</td>
									<td> 'Chocolate Chip' Ajuga</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Anemone x hybrida 'Pretty Lady Diana'</td>
									<td> 'Pretty Lady Diana' Japanese Anemone</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Anemone x hybrida 'Pretty Lady Julia'</td>
									<td> 'Pretty Lady Julia' Japanese Anemone</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Amsonia hubrichtii</td>
									<td>Amsonia</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Amsonia tabernaemontana 'Storm Cloud'</td>
									<td> 'Storm Cloud' Amsonia</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Amsonia tabernaemontana 'Storm Cloud'</td>
									<td> 'Storm Cloud' Amsonia</td>
									<td>3 gal</td>
								</tr>
								<tr>
									<td>Aspidistra elatior </td>
									<td>Cast Iron Plant</td>
									<td>2 gal</td>
								</tr>
								<tr>
									<td>Aspidistra elatior </td>
									<td>Cast Iron Plant</td>
									<td>3 gal</td>
								</tr>
								<tr>
									<td>Aster dumosus 'Lady in Blue'</td>
									<td> 'Lady in Blue' Aster</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Asteromea mongolica</td>
									<td>Mongolian Aster</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Astilbe × arendsii 'Amethyst'</td>
									<td> 'Amethyst' Astilbe</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Baptisia australis</td>
									<td>Blue False Indigo</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Canna 'Australia'</td>
									<td> 'Australia' Canna</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Canna x generalis</td>
									<td>Green Leaf - Orange Bloom</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Canna x generalis</td>
									<td>Green Leaf - Red Bloom</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Canna x generalis 'Black Knight'</td>
									<td> 'Black Knight' Canna</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>CannaSol™ Happy Carmen®'</td>
									<td> 'Happy Carmen®' Canna</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>CannaSol™ Happy Julia®'</td>
									<td> 'Happy Julia®' Canna</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Chrysogonum virginianum 'Pierre'</td>
									<td> 'Pierre' Chrysogonum</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Convallaria majalis</td>
									<td>Lily of the Valley</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Coreopsis verticillata 'Moonbeam' </td>
									<td> 'Moonbeam' Coreopsis</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Coreopsis verticillata 'Zagreb'</td>
									<td> 'Zagreb' Coreopsis</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Crocosmia 'Lucifer'</td>
									<td> 'Lucifer' Crocosmia</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Dianthus gratianopolitanus 'Firewitch'</td>
									<td> 'Firewitch' Dianthus</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Dietes iridioides</td>
									<td>African Iris</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Euonymus fortunei 'coloratus'</td>
									<td>Wintercreeper</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Euonymus fortunei 'coloratus'</td>
									<td>Wintercreeper</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Eupatorium dubium 'Baby Joe'</td>
									<td> 'Baby Joe' Joe Pye Weed</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Ficus repens</td>
									<td>Creeping Fig</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Gamolepis chrysanthemoides</td>
									<td>African Bush Daisy</td>
									<td>3 gal</td>
								</tr>
								<tr>
									<td>Hedera algeriensis 'Gloire de Marengo'</td>
									<td> 'Gloire de Marengo' Algerian Ivy</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Hedra helix</td>
									<td>English Ivy</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Hedra helix 'California Fan'</td>
									<td> 'California Fan' Ivy</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Hedera helix 'Glacier'</td>
									<td> 'Glacier' Ivy</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Hedera helix 'Gold Child'</td>
									<td> 'Gold Child' Ivy</td>
									<td>4" </td>
								</tr>
								<tr>
									<td>Hedera helix 'Ingelise'</td>
									<td> 'Ingelise' Ivy</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Hemerocallis 'Happy Returns'</td>
									<td>Daylily 'Happy Returns'</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hemerocallis 'Little Business' </td>
									<td>Daylily 'Little Business' </td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hemerocallis 'Moon Traveler' Yellow</td>
									<td>Daylily 'Moon Traveler' </td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hemerocallis 'Roadside Orange'</td>
									<td>Daylily 'Roadside Orange' </td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hemerocallis 'Pandora's Box'</td>
									<td>Daylily ' Pandora's Box'</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hemerocallis 'Stella d'Oro'</td>
									<td>Daylily 'Stella d'Oro' </td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hemerocallis 'Yangtze'</td>
									<td>Daylily 'Yangtze'</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hemerocallis sp.</td>
									<td>Daylily -Yellow</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Heuchera 'Obsidian' </td>
									<td> 'Obsidian' Heuchera</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Heuchera 'Forever Red'</td>
									<td> 'Forever Red' Heuchera</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Heucherella 'Gold Zebra'</td>
									<td> 'Gold Zebra' Heucherella</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Heuchera 'Palace Purple'</td>
									<td> 'Palace Purple' Heuchera</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Iris ensata 'Loyality'</td>
									<td> 'Loyality' Iris</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Iris 'Cherry Garden'</td>
									<td> 'Cherry Garden' Iris</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Iris 'Orange Caper'</td>
									<td> 'Orange Caper' Iris</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Iris pseudacorus </td>
									<td>Yellow Flag Iris</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Isotoma fluviatilis</td>
									<td>Blue Star Creeper</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Isotoma fluviatilis 'Alba'</td>
									<td>White Star Creeper</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Kniphofia uvaria 'Alcazar'</td>
									<td> 'Alcazar' Red Hot Poker</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Kniphofia 'Papaya Popsicle'</td>
									<td> 'Papaya Popsicle' Red Hot Poker</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Kniphofia 'Pineapple Popsicle'</td>
									<td> 'Pineapple Popsicle' Red Hot Poker</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Lantana camera 'Pink Huff'</td>
									<td> 'Pink Huff' Lantana</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Lavandula × intermedia 'Phenomenal'</td>
									<td> 'Phenomenal' Lavender</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Leucanthemum x superbum 'Becky'</td>
									<td> 'Becky' Shasta Daisy </td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Lilium 'Stargazer'</td>
									<td> 'Stargazer' Oriential Lily</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Lysimachia congestiflora 'Eco Dark Satin'</td>
									<td> 'Eco Dark Satin' Creeping Jenny</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Lysimachia nummularia 'Aurea' </td>
									<td>Creeping Jenny</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Lysimachia nummularia 'Aurea' </td>
									<td>Creeping Jenny</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Monarda didyma 'Balbalmurp'</td>
									<td> 'Balmy™ Purple' Bee Balm</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Origanum 'Kent Beauty'</td>
									<td> 'Kent Beauty' Ornamental Oregano</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Pachysandra terminalis </td>
									<td>Pachysandra</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Paeonia lactiflora 'Bowl of Beauty'</td>
									<td> 'Bowl of Beauty' Peony</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Paeonia lactiflora 'Duchesse de Nemours'</td>
									<td> 'Duchesse de Nemours' Peony - White</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Paeonia lactiflora 'Festiva Maxima'</td>
									<td> 'Festiva Maxima' Peony - White w/ Crimson</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Paeonia lactiflora 'Karl Rosenfield' </td>
									<td> 'Karl Rosenfield' Peony - Cherry Red</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Paeonia lactiflora 'Sarah Bernhardt'</td>
									<td> 'Sarah Bernhardt' Peony - Pink</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Paeonia lactiflora 'Shirley Temple'</td>
									<td> 'Shirley Temple' Peony - White</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Phlox paniculata 'Flame Blue'</td>
									<td> 'Flame Blue' Phlox</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Phlox paniculata 'Flame White Eye'</td>
									<td> 'Flame White Eye' Phlox</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Phlox subulata 'Drummond's Pink'</td>
									<td> 'Drummond's Pink Phlox</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Phlox subulata 'Snowflake'</td>
									<td> 'Snowflake' Phlox</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Rohdea japonica</td>
									<td>Nippon Lily</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Rubus calcynoides</td>
									<td>Creeping Raspberry</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Rubus calcynoides</td>
									<td>Creeping Raspberry</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Rudbeckia fulgida var.sullivantii 'Goldsturm' </td>
									<td> 'Goldsturm' Black Eye Susan</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Ruellia humilis</td>
									<td>Wild Petunia</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Ruellia metziae</td>
									<td>White Wild Petunia</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Salvia guaranitica 'Black and Blue' </td>
									<td> 'Black and Blue' Salvia</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Salvia greggii 'Furman's Red'</td>
									<td> 'Furman's Red' Salvia</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Salvia greggii 'Arctic Blaze® Fuchsia'</td>
									<td> 'Arctic Blaze® Fuchsia' Salvia</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Salvia greggii 'Arctic Blaze® Purple'</td>
									<td> 'Arctic Blaze® Purple' Salvia</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Salvia greggii 'Arctic Blaze® Red'</td>
									<td> 'Arctic Blaze® Red' Salvia</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Salvia greggii 'Radio Red'</td>
									<td> 'Radio Red' Salvia</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Salvia nemerosa 'Lyrical™ Blues '</td>
									<td> 'Lyrical™ Blues' Salvia</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Salvia nemerosa 'Lyrical™ White'</td>
									<td> 'Lyrical™ White' Salvia</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Salvia × sylvestris 'May Night'</td>
									<td> 'May Night' Salvia</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Santolina chamaecyparissus</td>
									<td>Gray Cotton</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Scabiosa 'Butterfly Blue'</td>
									<td> 'Butterfly Blue' Pincushion Flower</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Sedum makinoi ‘Ogon’ </td>
									<td> 'Ogon' Sedum</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Sedum rupestre 'Autumn Joy'</td>
									<td> 'Autumn Joy' Sedum</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Sedum rupestre 'Lemon Ball'</td>
									<td> 'Lemon Ball' Sedum</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Sedum rupestre 'Lemon Ball'</td>
									<td> 'Lemon Ball' Sedum</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Sedum tetractinum</td>
									<td>Tetractinum Sedum</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Sedum tetractinum</td>
									<td>Tetractinum Sedum</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Setcreasea pallida 'Purple Queen'</td>
									<td> 'Purple Queen' Setcreasea</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Solidago rugosa 'Fireworks' </td>
									<td> 'Fireworks' Goldenrod</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Stachys officinalis 'Hummelo'</td>
									<td> 'Hummelo' Lamb's Ear</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Thymus x citriodorus 'Golden Lemon'</td>
									<td> 'Golden Lemon' Thyme</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Thymus serpyllum 'Elfin'</td>
									<td> 'Elfin' Thyme</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Thymus serpyllum 'Pink Chintz'</td>
									<td> 'Pink Chintz' Thyme</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Trachelospermum asiaticum</td>
									<td>Asiatic Jasmine</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Trachelospermum asiaticum</td>
									<td>Asiatic Jasmine</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Trachelospermum asiaticum 'Summer Sunset'</td>
									<td> 'Summer Sunset' Asiatic Jasmine</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Trachelospermum asiaticum 'Variegatum'</td>
									<td>Variegated Asiatic Jasmine</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Tradescantia ohiensis 'Mrs Loewer'</td>
									<td> 'Mrs Loewer' Spiderwort</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Tricyrtis formosana 'Purple Beauty'</td>
									<td> 'Purple Beauty' Toad Lily</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Verbena canadensis 'Homestead Purple' </td>
									<td> 'Homestead Purple'  Verbena</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Veronica peduncularis 'Georgia Blue'</td>
									<td> 'Georgia Blue' Veronica</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Vinca minor</td>
									<td>Vinca minor</td>
									<td>4"</td>
								</tr>
							</tbody>
						</table>
					</div>
					<hr id='two' />

					<h3>Hosta</h3>
					<Scroll type="id" element="one">
						<a href="#one" className="button">
							Perennials and Groundcover
						</a>
					</Scroll>
					<Scroll type="id" element="three">
						<a href="#three" className="button">
							Ferns
						</a>
					</Scroll>
					<Scroll type="id" element="four">
						<a href="#four" className="button">
							Grasses
						</a>
					</Scroll>
					<div className="table-wrapper">
						<ul className="features">


							<li className="">
								<div className="image fit">
									<img src={june} alt="" />
								</div>
								<h3>Hosta 'June'</h3>
								<p>'June' Hosta</p>
							</li>


						</ul>

						<table className="alt">
							<thead>
								<tr>
									<th>Botanical Name</th>
									<th>Common Name</th>
									<th>Size</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Hosta fortunei 'Aurea Marginata'</td>
									<td>Hosta 'Aurea Marginata'</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hosta plantaginea</td>
									<td>Hosta plantaginea</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hosta ventricosa</td>
									<td>Hosta ventricosa</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hosta sieboldiana 'Francis Williams'</td>
									<td>Hosta 'Francis Williams'</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hosta  undulata 'Albo Marginata'</td>
									<td>Hosta 'Albo Marginata'</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hosta 'August Moon'</td>
									<td>Hosta 'August Moon'</td>
									<td>1 gal </td>
								</tr>
								<tr>
									<td>Hosta 'Blue Angel'</td>
									<td>Hosta 'Blue Angel'</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hosta 'Blue Mouse Ears'</td>
									<td>Hosta 'Blue Mouse Ears'</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hosta 'Diana Remembered'</td>
									<td>Hosta 'Diana Remembered'</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hosta 'Dream Weaver'</td>
									<td>Hosta 'Dream Weaver'</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hosta Shadowland® 'Empress Wu'</td>
									<td>Hosta Shadowland® 'Empress Wu'</td>
									<td>2 gal</td>
								</tr>
								<tr>
									<td>Hosta 'Fire and Ice'</td>
									<td>Hosta 'Fire and Ice'</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hosta 'Francee'</td>
									<td>Hosta 'Francee'</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hosta 'Glad Tidings'</td>
									<td>Hosta 'Glad Tidings'</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hosta 'Gold Standard'</td>
									<td>Hosta 'Gold Standard'</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hosta 'Golden Tiara'</td>
									<td>Hosta 'Golden Tiara'</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hosta 'June'</td>
									<td>Hosta 'June'</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hosta 'Loyalist'</td>
									<td>Hosta 'Loyalist'</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hosta 'Minute Man'</td>
									<td>Hosta 'Minute Man'</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hosta 'Sagae'</td>
									<td>Hosta 'Sagae'</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hosta 'Regal Splender'</td>
									<td>Hosta 'Regal Splender'</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hosta 'Robert Frost'</td>
									<td>Hosta 'Robert Frost'</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Hosta 'Royal Standard'</td>
									<td>Hosta 'Royal Standard'</td>
									<td>1 gal</td>
								</tr>
							</tbody>
						</table>
					</div>
					<hr id='three' />

					<h3>Ferns</h3>
					<Scroll type="id" element="one">
						<a href="#one" className="button">
							Perennials and Groundcover
						</a>
					</Scroll>
					<Scroll type="id" element="two">
						<a href="#two" className="button">
							Hosta
						</a>
					</Scroll>
					<Scroll type="id" element="four">
						<a href="#four" className="button">
							Grasses
						</a>
					</Scroll>
					<div className="table-wrapper">
						<ul className="features">


							<li className="">
								<div className="image fit">
									<img src={autumn} alt="" />
								</div>
								<h3>Autumn fern</h3>
								<p>Dryopteris erythrosora</p>
							</li>


						</ul>

						<table className="alt">
							<thead>
								<tr>
									<th>Botanical Name</th>
									<th>Common Name</th>
									<th>Size</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Adiantum pedatum</td>
									<td>Five Finger Maiden Hair Fern</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Arachniodes davalliaeformis</td>
									<td>Shiny Bristle Fern</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Athyrium angustum forma rubellum 'Lady in Red'</td>
									<td> 'Lady in Red' Fern</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Athyrium niponicum var. pictum</td>
									<td>Japanese Painted Fern </td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Cyrtomium falcatum</td>
									<td>Japanese Holly Fern </td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Cyrtomium falcatum</td>
									<td>Japanese Holly Fern </td>
									<td>3 gal</td>
								</tr>
								<tr>
									<td>Dryopteris × australis</td>
									<td>Dixie Wood Fern</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Dryopteris erythrosora 'Brilliance'</td>
									<td>Autumn Brilliance Fern</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Dryopteris filix-mas 'Parsley'</td>
									<td>Parsley Leaf Male Fern</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Dryopteris ludoviciana</td>
									<td>Southern Shield Fern</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Dryopteris pulcherrima</td>
									<td>Beautiful Wood Fern</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Onoclea sensibilis</td>
									<td>Sensitive Fern</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Osmunda regalis</td>
									<td>Royal Fern</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Thelypteris decursive-pinnata</td>
									<td>Japanese Beech Fern</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Polystichum acrostichoides</td>
									<td>Christmas Fern </td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Polystichum munitum</td>
									<td>Western Sword Fern</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Polystichum polyblepharum</td>
									<td>Tassel Fern</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Selaginella braunii</td>
									<td>Arborvitae Fern</td>
									<td>3 gal</td>
								</tr>
								<tr>
									<td>Selaginella kraussiana</td>
									<td>Krauss Green Spikemoss</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Thelypteris kunthi </td>
									<td>Southern Riverwood Fern</td>
									<td>1 gal</td>
								</tr>
							</tbody>
						</table>
					</div>
					<hr id='four' />

					<h3>Grasses</h3>
					<Scroll type="id" element="one">
						<a href="#one" className="button">
							Perennials and Groundcover
						</a>
					</Scroll>
					<Scroll type="id" element="two">
						<a href="#two" className="button">
							Hosta
						</a>
					</Scroll>
					<Scroll type="id" element="three">
						<a href="#three" className="button">
							Ferns
						</a>
					</Scroll>
					<ul className="features">
						<li className="">

							<div className="image fit">
								<img src={variegata} alt="" />
							</div>
							<h3>Variegated Liriope</h3>
							<p>Liriope muscari 'Variegata'</p>
						</li>
						<li className="">
							<div className="image fit">
								<img src={acorus} alt="" />
							</div>
							<h3>Golden Sweet Flag</h3>
							<p>Acorus gramineus 'Ogon'</p>
						</li>
						<li className="">
							<div className="image fit">
								<img src={dwarfmondo} alt="" />
							</div>
							<h3>Dwarf Mondo Grass</h3>
							<p>Ophiopogon japonicus 'Nana'</p>
						</li>
						<li className="">
							<div className="image fit">
								<img src={silverdragon} alt="" />
							</div>
							<h3>Liriope Silver Dragon</h3>
							<p>Liriope spicata 'Silver Dragon'</p>
						</li>
					</ul>
					<div className="table-wrapper">
						<table className="alt">
							<thead>
								<tr>
									<th>Botanical Name</th>
									<th>Common Name</th>
									<th>Size</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Acorus gramineus 'Ogon'</td>
									<td>Golden Sweet Flag</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Chasmanthium latifolium </td>
									<td>Indian wood-oats, northern sea oats</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Carex amphibola</td>
									<td>Creek Sedge</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Carex 'Phoenix Green™'</td>
									<td> 'Phoenix Green™' Carex</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Carex buchananii 'Red Rooster'</td>
									<td> 'Red Rooster' Carex</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Carex oshimensis 'Everoro'</td>
									<td> 'Everoro' Carex</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Carex testacea 'Prairie Fire' </td>
									<td> 'Prairie Fire' Carex</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Equisetum hyemale </td>
									<td>Horsetail Reed Grass</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Equisetum hyemale </td>
									<td>Horsetail Reed Grass</td>
									<td>3 gal</td>
								</tr>
								<tr>
									<td>Festuca glauca 'Elijah Blue'</td>
									<td> 'Elijah Blue' Fescue</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Liriope muscari 'Big Blue' </td>
									<td> 'Big Blue' Liriope </td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Liriope muscari 'Big Blue' </td>
									<td> 'Big Blue' Liriope </td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Liriope muscari 'Royal Purple' </td>
									<td> 'Royal Purple' Liriope</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Liriope muscari 'Royal Purple' </td>
									<td> 'Royal Purple' Liriope</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Liriope spicata </td>
									<td>Liriope Spicata </td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Liriope spicata </td>
									<td>Liriope Spicata </td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Liriope spicata 'Silver Dragon'</td>
									<td> 'Silver Dragon' Liriope Spicata </td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Liriope muscari 'Variegata' </td>
									<td>Liriope Variegated </td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Liriope muscari 'Variegata' </td>
									<td>Liriope Variegated </td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Miscanthus 'Grascillimus'</td>
									<td>Maiden Grass</td>
									<td>3 gal</td>
								</tr>
								<tr>
									<td>Miscanthus sinensis 'Adagio' </td>
									<td> 'Adagio' Maiden Grass</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Miscanthus sinensis 'Adagio' </td>
									<td> 'Adagio' Maiden Grass</td>
									<td>3 gal</td>
								</tr>
								<tr>
									<td>Miscanthus sinensis 'Little Zebra' </td>
									<td> 'Little Zebra' Maiden Grass</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Miscanthus sinensis 'Morning Light'</td>
									<td> 'Morning Light' Miscanthus</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Miscanthus sinensis 'Variegatus'</td>
									<td>Variegated Miscanthus</td>
									<td>3 gal</td>
								</tr>
								<tr>
									<td>Muhlenbergia capillaris</td>
									<td>Muhly Grass </td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Muhlenbergia capillaris</td>
									<td>Muhly Grass </td>
									<td>3 gal</td>
								</tr>
								<tr>
									<td>Ophiopogon japonicus </td>
									<td>Mondo Grass</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Ophiopogon japonicus </td>
									<td>Mondo Grass </td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Ophiopogon japonicus 'Silver Mist'</td>
									<td> 'Silver Mist' Mondo Grass</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Ophiopogon japonicus 'Nana'</td>
									<td>Dwarf Mondo Grass</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Ophiopogon japonicus 'Nana'</td>
									<td>Dwarf Mondo Grass</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Panicum virgatum 'Heavy Metal'</td>
									<td> 'Heavy Metal' Switch Grass</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Pennisetum alopecuroides 'Little Bunny'</td>
									<td> 'Little Bunny' Fountain Grass</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Sisyrinchium angustifolium 'Blue Note'</td>
									<td> 'Blue Note' Blue Eyed Grass</td>
									<td>4"</td>
								</tr>
								<tr>
									<td>Sisyrinchium angustifolium 'Blue Note'</td>
									<td> 'Blue Note' Blue Eyed Grass</td>
									<td>1 gal</td>
								</tr>
								<tr>
									<td>Spartina bakeri</td>
									<td>Sandcord Grass</td>
									<td>3 gal</td>
								</tr>
							</tbody>
						</table>
					</div>


				</div>
				<hr />
				<div className='inner'>
					<Form></Form>
				</div>
			</section>
		</article>

	</Layout>
);

export default IndexPage;
